import React from 'react';
import Layout from '../components/layout';
import WordRotator from '../components/word-rotator';
import Testimonial from '../components/testimonial';
import CloudSolutions from '../components/cloud-solutions';

class IndexPage extends React.Component {
  /*
    var loadScript = function(src) {
      var tag = document.createElement('script');
      tag.async = false;
      tag.src = src;
      document.getElementsByTagName('body').appendChild(tag);
    }
    loadScript('//cdnjs.com/some/library.js')
    loadScript('//cdnjs.com/some/other/library.js')
    */
  render() {

    return (
      <div>

        <Layout>

          <section className="homepage-landing-page-section">

            <div className="hero-section">

              <div className="slider-3 w-slider">

                <div className="mask w-slider-mask">

                  <div className="slide-27 w-slide">
                    <div className="container">
                      <div className="div-block-103 text-centerX">
                        <div className="h1">You Have Ideas.<br/>We Bring Them to Life.</div>
                        <h4 className="hero-desc">We're a full-cycle consulting and software development company, with expert teams experienced in product development, with a special emphasis on Payment and Hospitality.</h4>
                        <div className="secondary-cta">
                          <div className="d-flex justify-content-centerX">
                          <a href="https://calendly.com/quantic/15min" className="km-button km-button--primary cta-signup-btn" target="_blank"
                             rel="noopener noreferrer">
                            Let's Talk
                          </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-slider-arrow-left">
                  <div className="milestone-arrow w-icon-slider-left"></div>
                </div>
                <div className="w-slider-arrow-right">
                  <div className="w-icon-slider-right"></div>
                </div>
                <div className="slide-nav w-slider-nav w-round">
                  <div className="w-slider-dot" data-wf-ignore=""></div>
                  <div className="w-slider-dot" data-wf-ignore=""></div>
                  <div className="w-slider-dot" data-wf-ignore=""></div>
                  <div className="w-slider-dot" data-wf-ignore=""></div>
                  <div className="w-slider-dot w-active" data-wf-ignore=""></div>
                </div>
              </div>

            </div>

          </section>
          {/*
          pl-md-0 d-none d-sm-block
          pl-xs-0
          */}

          <div className="services-grid-container">
            <div className="home-page-services-grid">
              <div className="container">
                <div className="row homepage-card">
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                    <div className="card">
                      <div className="card-body">
                        <img className="img-thumbnail-responsive service-icon" src="/images/linea/data.svg" alt=""/>
                        <h5 className="card-title">Data Science</h5>
                        <div className="services-content">Providing best-in-class solutions in ML and AI specializing in Computer Vision, NLP and Reinforcement Learning</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                    <div className="card">
                      <div className="card-body">
                        <img className="img-thumbnail-responsive service-icon" src="/images/linea/cloud-computing.svg" alt=""/>
                        <h5 className="card-title">Managed Cloud</h5>
                        <div className="services-content">Free up resources for innovation and growth with our round the clock managed hosting services.</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                    <div className="card">
                      <div className="card-body">
                        <img className="img-thumbnail-responsive service-icon" src="/images/linea/repair.svg" alt=""/>
                        <h5 className="card-title">App Development</h5>
                        <div className="services-content">Simplify your IT infrastructure and turn data into real-time decisions with an enterprise grade global
                          app.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 col-xs-12 text-center">
                    <div className="card">
                      <div className="card-body">
                        <img className="img-thumbnail-responsive service-icon" src="/images/linea/gdpr.svg" alt=""/>
                        <h5 className="card-title">Regulatory Compliance</h5>
                        <div className="services-content">Fulfill regulatory compliance requirements and turn your legacy systems from a liability to an
                          asset.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <CloudSolutions/>
          <Testimonial/>
          <br/>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center"><h2 className="section-heading">Quantic customers have seen</h2>
                <hr className="hr"/>
              </div>
            </div>
            <br/>
            <div className="stats__wrapper">
              <div className="stats__items">
                <div className="stats__item">
                  <img src="./images/17_new.svg" alt="revenue"/>
                  <h4 className="stats__description">increase in revenue</h4>
                </div>
                <div className="stats__item">
                  <img src="./images/7x_new.svg" alt="retention"/>
                  <h4 className="stats__description">higher user retention</h4>
                </div>
                <div className="stats__item">
                  <img src="./images/9.6x_new.svg" alt="conversions"/>
                  <h4 className="stats__description">more conversions</h4>
                </div>
              </div>
            </div>
          </div>

          <div className="section-cloud-journey">
            <div className="container">
              <div className="w-100">
                <h3>Start your Journey to the Cloud.</h3>
                <div>Public, Private, Hybrid and Multi-cloud solutions for all your needs.</div>

                <div className="col-lg-6 col-sm-12 lets-go-box p-xm-0">
                  <div className="box-message-wrap">
                    {/*<div className="box-message">I want improved</div>*/}
                    <div className="box-message">My company needs</div>
                    <WordRotator/>
                  </div>
                  <div className="">
                    <a href="https://calendly.com/quantic/15min" className="km-button km-button--primary cta-signup-btn" target="_blank"
                       rel="noopener noreferrer">
                      Let's Talk
                    </a>
                  </div>
                  <br/>
                  <div className="row">
                    <div className="col-4 m-auto">
                      <img className="logo-gcp" src="./images/logo-gcp-2.png" alt="gcp"/>
                    </div>
                    <div className="col-4 m-auto">
                      <img className="logo-azure" src="./images/logo-azure.png" alt="azure"/>
                    </div>
                    <div className="col-4 m-auto">
                      <img className="logo-aws" src="./images/logo_aws.png" alt="aws"/>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </Layout>

      </div>
    );
  }
}

export default IndexPage;


/*


          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1442.484 241.391" className="arch">
            <path fill="#fff"
                  d="M0 241.375s31.86-62.023 155.407-118.648 315.731-66.921 442.71-44.614 245.378 61.774 401.528 70.353S1347.87 139.218 1442.484.001c-.031 4.109-.031 110.39-.031 115.562 0 .875.014 116.719.031 125.828-178.221.011-1184.957-.526-1442.484-.016z"
                  data-name="Path 1"></path>
          </svg>



           <section className="section-home">

       <WordRotator />

        </section>






          <h1>Delight your customers with intelligent chat-based support</h1>
          <a href="https://calendly.com/quantic/15min" className="calendly-url km-button km-button--primary cta-signup-btn" target="_blank">Request a
            demo</a>


          <p className="no-credit-card-text">30 days trial. Quick setup. No credit card required.</p>

          <div
            className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-divs">
            <div
              className="img-cont-pos-rel">
              <img
                src="./images/homepage-bg-girl.png"
                alt=""
                className="img-responsive bg-girl-img"/>

            </div>
          </div>
 */
