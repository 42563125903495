import React from 'react'


const Testimonial = () => (

    <section className="homepage-customer-testimonial-section homepage-ab-customer-testimonial-section">
      <div className="top-circle-div">

        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 238.918 153.75">
          <defs>
            <clipPath id="top-circle-div-svg-clip-path">
              <path id="Mask"
                    d="M-3.024 92.57C9.554 39.107-17.292 0-80.1 0s-91.822.235-127.143 17.2-48.625 75.375 0 89.445S-136.61 153.75-80.1 153.75s64.5-7.716 77.076-61.18z"
                    className="top-circle-div-svg-1" transform="rotate(-180 .2145 0)"></path>
            </clipPath>
          </defs>
          <g id="Group_2" data-name="Group 2" opacity=".671" transform="rotate(180 119.6735 0)">
            <path id="Mask-2"
                  d="M-3.024 92.57C9.554 39.107-17.292 0-80.1 0s-91.822.235-127.143 17.2-48.625 75.375 0 89.445S-136.61 153.75-80.1 153.75s64.5-7.716 77.076-61.18z"
                  className="top-circle-div-svg-1" data-name="Mask" transform="rotate(-180 .2145 0)"></path>
            <g id="Group_2-2" clipPath="url(#top-circle-div-svg-clip-path)" data-name="Group 2">
              <g id="Group_2-3" data-name="Group 2" transform="translate(-117 59)">
                <path id="Line_4" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180)"></path>
                <path id="Line_4-2" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 5.885 0)"></path>
                <path id="Line_4-3" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 11.7705 0)"></path>
                <path id="Line_4-4" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 17.6555 0)"></path>
                <path id="Line_4-5" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 23.5405 0)"></path>
                <path id="Line_4-6" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 29.426 0)"></path>
                <path id="Line_4-7" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 35.311 0)"></path>
                <path id="Line_4-8" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 41.196 0)"></path>
                <path id="Line_4-9" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 47.0815 0)"></path>
                <path id="Line_4-10" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 52.9665 0)"></path>
                <path id="Line_4-11" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 58.8515 0)"></path>
                <path id="Line_4-12" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 64.737 0)"></path>
                <path id="Line_4-13" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 70.622 0)"></path>
                <path id="Line_4-14" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 76.507 0)"></path>
                <path id="Line_4-15" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 82.3925 0)"></path>
                <path id="Line_4-16" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 88.2775 0)"></path>
                <path id="Line_4-17" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 94.1625 0)"></path>
                <path id="Line_4-18" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 100.048 0)"></path>
                <path id="Line_4-19" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 105.933 0)"></path>
                <path id="Line_4-20" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 111.818 0)"></path>
                <path id="Line_4-21" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 117.7035 0)"></path>
                <path id="Line_4-22" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 123.5885 0)"></path>
                <path id="Line_4-23" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 129.4735 0)"></path>
                <path id="Line_4-24" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 135.359 0)"></path>
                <path id="Line_4-25" d="M-208.923.593L-.589 261.407" className="top-circle-div-svg-4" data-name="Line 4" transform="rotate(-180 141.244 0)"></path>
              </g>
            </g>
          </g>
        </svg>


      </div>

      <div className="container">
        <div className="row">
          <div className="col-lg-12 text-center"><h2 className="section-heading">Join the list of happy customers</h2>
            <hr className="hr"/>
          </div>
        </div>
        <div className="slider-container">
          <div className="slider-content">

            <div className="slider-singlee active">
              <div className="testimonials-container text-center slider-single-image">
                <div className="testimonials">
                  <div className="profile-pic">
                    <img src="/images/quote.jpg" alt="Joseph Anselmo, CEO - 1-800-Limo.com"/>
                  </div>
                  <div className="testimonial-by"><h3 className="testimonial-by-name">Joseph Anselmo, CEO</h3><p className="testimonial-by-designation"><a
                    href="http://www.1800limo.com/" target="_blank" rel="noopener noreferrer">1-800-Limo.com</a></p></div>
                  <div className="testimonial-quote">
                    <p>It has been a real pleasure dealing with the team. They are professional, responsive and helpful. We look forward to engaging our
                      customers powered by Quantic</p></div>
                  <div className="quote-img">

                    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="74">
                      <path fill="#6556D7"
                            d="M85.3406593 24.7802198c13.2967033 0 24.0549447 10.8791209 24.0549447 24.1758242S98.5164835 73.1318681 85.2197802 73.1318681c-13.2967033 0-24.1758242-10.8791208-24.1758242-24.1758241 0-26.7142858 21.6373627-48.3516484 48.351648-48.3516484 0 0-15.8351644 5.92307693-24.0549447 24.1758242zM48.956044 48.956044c0 13.2967033-10.8791209 24.1758241-24.1758242 24.1758241S.6043956 62.2527473.6043956 48.956044c0-26.7142858 21.6373626-48.3516484 48.3516484-48.3516484 0 0-15.8351649 5.92307693-24.0549451 24.1758242 13.2967033 0 24.0549451 10.8791209 24.0549451 24.1758242z"
                            opacity=".75999999"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <a className="slider-left" href="javascript:void(0);">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="4133.757 2548.836 62.499 62.499">
              <path fill="#6556d7"
                    d="M4158.09368807 2555.74996779l-23.41301263 23.41301263-.0091924.00919239a1.327 1.327 0 0 0-.27435742.41577878 1.29 1.29 0 0 0-.09758074.4893179.054.054 0 0 1-.00212132.00919238 1.32 1.32 0 0 0 .38183766.92206725l23.41301263 23.41301263a1.304 1.304 0 1 0 1.84413449-1.84413449l-21.18633338-21.18633337 55.66061739-.00141422a1.3042585 1.3042585 0 1 0-.0007071-2.60851691h-55.65920318l21.18774759-21.1877476a1.3035 1.3035 0 0 0-1.84342738-1.84342737"
                    data-name="arrow copy"></path>
            </svg>
          </a><a className="slider-right" href="javascript:void(0);">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="5288.745 2548.665 62.499 62.499">
            <path fill="#6556d7"
                  d="M5326.90631193 2604.25003221l23.41301263-23.41301263.0091924-.00919239a1.327 1.327 0 0 0 .27435742-.41577878 1.29 1.29 0 0 0 .09758074-.4893179.054.054 0 0 1 .00212132-.00919238 1.32 1.32 0 0 0-.38183766-.92206725l-23.41301263-23.41301263a1.304 1.304 0 1 0-1.84413449 1.84413449l21.18633338 21.18633337-55.66061739.00141422a1.3042585 1.3042585 0 1 0 .0007071 2.60851691h55.65920318l-21.18774759 21.1877476a1.3035 1.3035 0 0 0 1.84342738 1.84342737"></path>

          </svg>
        </a></div>
      </div>

      <div className="div-circle"></div>

    </section>


);

export default Testimonial

/*


            <div className="slider-singlee proactive">
              <div className="testimonials-container text-center slider-single-image">
                <div className="testimonials">
                  <div className="profile-pic"><img src="/images/quote-activ8me.jpg" alt="Jeremy Rolleston, CEO - Active8me"/>
                  </div>
                  <div className="testimonial-by"><h3 className="testimonial-by-name">RJ, CEO</h3>
                    <p className="testimonial-by-designation">Active8me</p></div>
                  <div className="testimonial-quote">
                    <p>It has been a real pleasure dealing with Dev and the team. They are professional, responsive and helpful. We look forward to engaging our
                      customers powered by quantic</p></div>
                  <div className="quote-img">

                    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="74">
                      <path fill="#6556D7"
                            d="M85.3406593 24.7802198c13.2967033 0 24.0549447 10.8791209 24.0549447 24.1758242S98.5164835 73.1318681 85.2197802 73.1318681c-13.2967033 0-24.1758242-10.8791208-24.1758242-24.1758241 0-26.7142858 21.6373627-48.3516484 48.351648-48.3516484 0 0-15.8351644 5.92307693-24.0549447 24.1758242zM48.956044 48.956044c0 13.2967033-10.8791209 24.1758241-24.1758242 24.1758241S.6043956 62.2527473.6043956 48.956044c0-26.7142858 21.6373626-48.3516484 48.3516484-48.3516484 0 0-15.8351649 5.92307693-24.0549451 24.1758242 13.2967033 0 24.0549451 10.8791209 24.0549451 24.1758242z"
                            opacity=".75999999"></path>

                    </svg>

                  </div>
                </div>
              </div>
            </div>

            <div className="slider-singlee proactivede">
              <div className="testimonials-container text-center slider-single-image">
                <div className="testimonials">
                  <div className="profile-pic"><img src="/images/quote-activ8me.jpg" alt="Jeremy Rolleston, CEO - Active8me"/></div>
                  <div className="testimonial-by"><h3 className="testimonial-by-name">JR, CEO</h3><p className="testimonial-by-designation">Active8me</p></div>
                  <div className="testimonial-quote"><p>It has been a real pleasure dealing with Dev and the team. They are professional, responsive and helpful. We
                    look forward to engaging our customers powered by quantic</p></div>
                  <div className="quote-img">

                    <svg xmlns="http://www.w3.org/2000/svg" width="110" height="74">
                      <path fill="#6556D7"
                            d="M85.3406593 24.7802198c13.2967033 0 24.0549447 10.8791209 24.0549447 24.1758242S98.5164835 73.1318681 85.2197802 73.1318681c-13.2967033 0-24.1758242-10.8791208-24.1758242-24.1758241 0-26.7142858 21.6373627-48.3516484 48.351648-48.3516484 0 0-15.8351644 5.92307693-24.0549447 24.1758242zM48.956044 48.956044c0 13.2967033-10.8791209 24.1758241-24.1758242 24.1758241S.6043956 62.2527473.6043956 48.956044c0-26.7142858 21.6373626-48.3516484 48.3516484-48.3516484 0 0-15.8351649 5.92307693-24.0549451 24.1758242 13.2967033 0 24.0549451 10.8791209 24.0549451 24.1758242z"
                            opacity=".75999999"></path>
                    </svg>
                  </div>
                </div>
              </div>
            </div>



      <script>$(document).ready(function(){var v=$(".slider-singlee"),t=v.length-1,c=-1;function e(){if(c<t?c++:c=0,0<c)var e=v.eq(c-1);else e=v.eq(t);var a=v.eq(c);if(c<t)var i=v.eq(c+1);else i=v.eq(0);v.each(function(){var e=$(this);e.hasClass("preactivede")&&e.removeClass("preactivede preactive active proactive").addClass("proactivede"),e.hasClass("preactive")&&e.removeClass("preactive active proactive proactivede").addClass("preactivede")}),e.removeClass("preactivede active proactive proactivede").addClass("preactive"),a.removeClass("preactivede preactive proactive proactivede").addClass("active"),i.removeClass("preactivede preactive active proactivede").addClass("proactive")}var a=$(".slider-left"),i=$(".slider-right");a.on("click",function(){!function(){if(0<c?c--:c=t,c<t)var e=v.eq(c+1);else e=v.eq(0);var a=v.eq(c);if(0<c)var i=v.eq(c-1);else i=v.eq(t);v.each(function(){var e=$(this);e.hasClass("proactivede")&&e.removeClass("preactive active proactive proactivede").addClass("preactivede"),e.hasClass("proactive")&&e.removeClass("preactivede preactive active proactive").addClass("proactivede")}),i.removeClass("preactivede active proactive proactivede").addClass("preactive"),a.removeClass("preactivede preactive proactive proactivede").addClass("active"),e.removeClass("preactivede preactive active proactivede").addClass("proactive")}()}),i.on("click",function(){e()}),v.addClass("proactivede"),setTimeout(function(){e()},500)})
      </script>

 */