import React from 'react';
import DacWordRotator from './dac-word-rotator'


class Comp extends React.Component {

  /*
   * Initializes on frame load.
   */
  //window.addEventListener('load', DacWordRotator.initialize);

  // initialize = () => {
  //   window.addEventListener('load', DacWordRotator.initialize);
  // };

  componentDidMount = () => {
    //window.addEventListener('load', DacWordRotator.initialize);
    DacWordRotator.initialize();
  };

  componentWillUnmount = () => {
    //window.removeEventListener('load', DacWordRotator.initialize);
  };


  render() {

    return (
      <div className="dac-word-rotator"
           data-words="Payment,In-store Customer Experiences,Data Science,Data Storage,Data Center & Cloud,Security,Safety,Online Store,eCommerce,Data Analytics,Merchandising,Supply Chain,Customer Management">
        <div className="is-entering is-active">Payment</div>
      </div>
    );
  }
}

export default Comp;
